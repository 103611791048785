body.work {
    .hero {
        background-image: url('/images/work_hero.jpg');
        @media screen and (max-width: 600px) {
            h1 {
                @include font-size(26);
            }    
        }
    }
    .menu {
        background: $work;
        ul li.active:after {
            border-top-color: $work;
        }
    }
    .blocks {
        .text {
            color: $dark_blue;
            &.work__connections-pattern, &.transport-pattern, &.available-space-pattern {                
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
            }
            &.work__connections-pattern {
                background-image: url('/images/connections_pattern.png');
                @media screen and (max-width: 980px) {
                    grid-row-start: 3 !important;
                    grid-row-end: 4 !important;
                }
            }
            &.transport-pattern {
                background-image: url('/images/transport_pattern.png');
            }
            &.available-space-pattern {
                background-image: url('/images/life_pattern.png');
                color: #FFF;
            }
        }
        .case_study {
            position: relative;
            padding-left: 140px;
            p {
                line-height: 120%;                
            }
            &:before {
                content: "Case Study";
                position: absolute;
                left: 85px;
                top: 50%;
                transform-origin: top left;
                transform: rotate(90deg) translateX(-50%);
                font-family: 'intro_black_capsregular' !important;
                line-height: 70%;            
                text-transform: uppercase; 
                color: #FFF;
                @include font-size(26);
            }
        }
        .teal, .lime, .plum, .blue {
            &, a {
                color: #FFF;        
            }
            .button {
                margin: 20px 0;
                &:hover {
                    color: $dark_blue;
                }
            }
        }
        .teal {
            background: $work_teal;
        }
        .lime {
            background: $work_lime;
            p, li {
                font-weight: 500;
                @include font-size(11);
                &.small {
                    font-weight: 300;
                }
            }
        }
        .plum {
            background: $work_plum;
            p {
                @include font-size(11);
            }
        }
        .blue {
            background: $light_blue;
        }
    }
    .work__carousel {
        $size: 33.33vw; 
        $perspective: $size * 256; 
        overflow: hidden;
        .scene {
            width: $size;
            height: $size;
            perspective: $perspective;
        }
        .cube {
            width: inherit;
            height: inherit;
            position: relative;
            perspective: $perspective;
            transform-style: preserve-3d;
            will-change: transform;
            animation: cubeCarousel 9s ease 3s infinite;
        }
        .cube-face {
            width: inherit;
            height: inherit;
            position: absolute;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
        }
        .cube-face-front {
            transform: translate3d(0, 0, $size/2);
        } 
        .cube-face-back {
            transform: rotateY(180deg) translate3d(0, 0, $size/2);
        } 
        .cube-face-left {
            transform: rotateY(-90deg) translate3d(0, 0, $size/2);
        } 
        .cube-face-right {
            transform: rotateY(90deg) translate3d(0, 0, $size/2);
        } 
        .cube-face-top {
            transform: rotateX(90deg) translate3d(0, 0, $size/2);
        } 
        .cube-face-bottom {
            transform: rotateX(-90deg) translate3d(0, 0, $size/2);
        }
        @media screen and (min-width: 1500px) {
            .scene {
                width: 500px;
                height: 500px;
                perspective: calc(500px * 256);
            } 
            .cube {
                perspective: calc(500px * 256);
            }
            .cube-face-front {
                transform: translate3d(0, 0, 250px);
            } 
            .cube-face-back {
                transform: rotateY(180deg) translate3d(0, 0, 250px);
            } 
            .cube-face-left {
                transform: rotateY(-90deg) translate3d(0, 0, 250px);
            } 
            .cube-face-right {
                transform: rotateY(90deg) translate3d(0, 0, 250px);
            } 
            .cube-face-top {
                transform: rotateX(90deg) translate3d(0, 0, 250px);
            } 
            .cube-face-bottom {
                transform: rotateX(-90deg) translate3d(0, 0, 250px);
            }
        }
        @media screen and (max-width: 1180px) {
            .scene {
                width: 50vw;
            }
            .scene, .cube {
                perspective: calc(50vw * 256);
            }
            .cube-face-front {
                transform: translate3d(0, 0, 25vw);
            } 
            .cube-face-back {
                transform: rotateY(180deg) translate3d(0, 0, 25vw);
            } 
            .cube-face-left {
                transform: rotateY(-90deg) translate3d(0, 0, 25vw);
            } 
            .cube-face-right {
                transform: rotateY(90deg) translate3d(0, 0, 25vw);
            } 
            .cube-face-top {
                transform: rotateX(90deg) translate3d(0, 0, 25vw);
            } 
            .cube-face-bottom {
                transform: rotateX(-90deg) translate3d(0, 0, 25vw);
            }
        }
        @media screen and (max-width: 980px) {
            .scene {
                width: 100vw;
                height: 100vw;
            }
            .scene, .cube {
                perspective: calc(100vw * 256);
            }
            .cube-face-front {
                transform: translate3d(0, 0, 50vw);
            } 
            .cube-face-back {
                transform: rotateY(180deg) translate3d(0, 0, 50vw);
            } 
            .cube-face-left {
                transform: rotateY(-90deg) translate3d(0, 0, 50vw);
            } 
            .cube-face-right {
                transform: rotateY(90deg) translate3d(0, 0, 50vw);
            } 
            .cube-face-top {
                transform: rotateX(90deg) translate3d(0, 0, 50vw);
            } 
            .cube-face-bottom {
                transform: rotateX(-90deg) translate3d(0, 0, 50vw);
            }
        }
    }
    .work__agent-logos {
        margin: 0;
        & > div {
            &:first-child {
                padding-left: 0;                
            }
            &:last-child {
                padding-right: 0;
            }
        }
    }
    .work__plan {
        background: url("/images/available_space_plan.png") top right no-repeat $light_blue;
        background-size: contain;
        height: 33vw;
        padding: 50px;        
        p {
            line-height: 130%;
            max-width: 300px;
            color: #FFF;
            @include font-size(11);
            strong {
                font-weight: 500;
            }
            .button {
                display: block;
                width: 208px;
                color: #FFF;
                padding: 5px 10px;
                margin-bottom: 10px;
                text-align: center;
                @include font-size(9);
                &:hover {
                    color: $light_blue;
                }
                @media screen and (max-width: 600px) {
                    width: 100%;    
                }
            }
        }
        .work__plan--image {
            display: none;
        }
        @media screen and (min-width: 1500px) {
            position: relative;
            height: 500px;
            background-image: none;
            .work__plan--image {
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                img {
                    height: 500px;
                }
                a {
                    position: absolute;
                    display: block;
                    top: 0;
                    left: 0;
                    &:first-of-type {
                        top: 154px;
                        left: 214px;
                        width: 78px;
                        height: 62px;
                    }
                    &:nth-of-type(2) {
                        top: 216px;
                        left: 235px;
                        width: 56px;
                        height: 30px;
                    }
                    &:nth-of-type(3) {
                        top: 150px;
                        left: 808px;
                        width: 125px;
                        height: 48px;
                    }
                }
            }
        }
        @media screen and (max-width: 1100px) {
            height: auto;
            background: $light_blue;
            p {
                max-width: 600px;
            }
        }
        @media screen and (max-width: 600px) {
            padding: 25px;
        }
    }
    .work__modal {
        display: none;
        padding: 50px;
        max-width: 90%;
        p, ul {
            margin-bottom: 1.5em;
        }
        p, li, h3 {
            color: $dark_blue;
            @include font-size(11);
        }
        ul {
            margin-left: 20px;
            li {
                margin: 0;
                list-style-type: disc;
            }
        }
        h2 {
            margin-bottom: 40px;
            text-align: center;
            font-weight: 500;
            color: $work_teal;
            @include font-size(20);
        }
        h3 {
            margin: 5px 0 10px;
            font-weight: 500;
            @include font-size(12);
        }
        .work__modal--small-print {
            margin: 0;
            @include font-size(8);
        }
        &#property-3-ariadne-house h2 {
            color: $mid_blue;
        }
        &#property-7-beresford-house {
            img {
                margin-bottom: 35px;
            }
            h2 {
                color: $light_blue;
            }
        }
    }
}

body.work.local-area .main-image {
    background-image: url('/images/work_local_area_hero.jpg');
}