@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes cubeCarousel {
    0% {
        transform: rotate(0deg);
    }
    13% {
        transform: rotateY(90deg);
    }
    26% {
        transform: rotateY(90deg);
    }
    40% {
        transform: rotateY(180deg);
    }
    53% {
        transform: rotateY(180deg);
    }
    67% {
        transform: rotateY(270deg);
    }
    80% {
        transform: rotateY(270deg);
    }
    93% {
        transform: rotateY(360deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}