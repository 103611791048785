.home__content {
    display: grid;
    grid-template-columns: auto 250px;
    grid-template-rows: 250px 250px 250px;
    .home__content--hero {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 4;
        -ms-grid-column-span: 1;
        -ms-grid-row-span: 3;
        position: relative;
        height: 750px;
        background-image: url('/images/home_hero_2.jpg');
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        &:before, &:after {
            content: "";
            position: absolute;
            left: 0;
            width: 100%;
            height: 200px;
        }
        &:before {
            top: 0;
            height: 280px;
            background: -moz-linear-gradient(top, rgba(65, 192, 240, .6) 0%, rgba(0,0,0,0) 100%); 
            background: -webkit-linear-gradient(top, rgba(65, 192, 240, .6) 0%,rgba(0,0,0,0) 100%); 
            background: linear-gradient(to bottom, rgba(65, 192, 240, .6) 0%,rgba(0,0,0,0) 100%);
        }
        &:after {            
            bottom: 0;            
            background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); 
            background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); 
            background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
        }
        h1, h2 {
            position: absolute;
            left: 0;
            z-index: 100;
            width: 100%;
            text-align: center;
            color: #FFF;
        }
        h1 {            
            top: 70px;            
            font-family: 'intro_black_capsregular' !important;
            line-height: 70%;            
            text-transform: uppercase;            
            @include font-size(78);
            span {
                display: block;
                @include font-size(32);
            }
            @media screen and (max-width: 980px) {
                @include font-size(64);
                span {
                    @include font-size(26);
                }
            }
            @media screen and (max-width: 600px) {
                @include font-size(46);
                span {
                    @include font-size(20);
                }
            }
        }
        h2 {
            bottom: 40px;
            left: 50%;
            transform: translateX(-50%);
            max-width: 620px;
            margin: 0 auto;
            font-weight: 500;
            @include font-size(18);
            @media screen and (max-width: 600px) {
                @include font-size(13);
                padding: 0 25px;
            }
        }
    }
    .home__content--block {
        grid-column-start: 2;
        grid-column-end: 3;
        position: relative;
        z-index: 200;        
        overflow: hidden; 
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        &:before, &:after, & > a {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 130;
        }
        &:before {
            width: 500px;
            height: 500px;
            opacity: 1;
            transform: rotate(45deg) translateX(-427px);
            transition: all .5s ease;
            z-index: 140;
            will-change: transform;
        }
        & > a {  
            z-index: 150;
            h3 {
                position: absolute;
                left: 25px;
                top: 45px;
                z-index: 170;
                transition: all .4s ease;
                font-family: 'intro_black_capsregular' !important;
                line-height: 70%;            
                text-transform: uppercase;
                text-align: center;
                color: #FFF;
                @include font-size(19);
            } 
            .home__content--block_content, .button, & > img {
                position: absolute;
                left: 50px;
                opacity: 0;
                color: #FFF;
            }
            .home__content--block_content {                
                top: 50%;
                transform: translateY(-50%);
                h4 {                    
                    max-width: 520px;
                    line-height: 110%;
                    font-weight: 300;
                    @include font-size(32);
                }
                p {
                    max-width: 520px;
                    margin-top: 30px;
                    line-height: 110%;
                    font-weight: 500;
                }                
            }
            .button {
                bottom: 50px;
                z-index: 220;
                &:hover {
                    color: #333;
                }
            }
            & > img {
                left: auto;
                right: 50px;
                top: 50px;
                width: 32px;
            }
        } 
        &:hover:before {
            transform: rotate(45deg) translateX(-227px);
        }
        &.work {
            grid-row-start: 1;
            grid-row-end: 2;            
            z-index: 200;
            background-image: url('/images/work_block.jpg');
            &:before {
                background-color: rgba($work, .85);
            }
            &:after {
                background-color: rgba($work, .6);
            }
        }
        &.marina {
            grid-row-start: 2;
            grid-row-end: 3;
            background-image: url('/images/marina_block.jpg');
            &:before {
                background-color: rgba($marina, .85);
            }
            &:after {
                background-color: rgba($marina, .6);
            }
        }
        &.life {
            grid-row-start: 3;
            grid-row-end: 4;
            background-image: url('/images/life_block.jpg');
            &:before {
                background-color: rgba($life, .85);
            }
            &:after {
                background-color: rgba($life, .6);
            }
        }
        &.open {
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 4;
            z-index: 210;
            &:before {
                opacity: 0;
            }
            h3 {
                top: 60px;
                left: 50px;
                @include font-size(32); 
            }
            .home__content--block_content, .button, a > img {                
                animation: fadeIn 1s ease .25s forwards;
            }
        }
        &:not(.open):hover .home__content--block_background {
            transform: scale(1.1);
        }
    }    
    @media screen and (min-width: 1500px) {
        grid-template-columns: 1150px 350px;
        grid-template-rows: 350px 350px 350px;
        .home__content--hero {        
            height: 1050px;
        }
        .home__content--block {
            &:before {
                width: 700px;
                height: 700px;
                transform: rotate(45deg) translateX(-598px);
            }
        }
    }
    @media screen and (max-width: 980px) {
        grid-template-columns: 100vw;
        grid-template-rows: auto auto auto auto;
        .home__content--hero {
            grid-row-start: 1;
            grid-row-end: 2;
            height: 100vw;
        }
        .home__content--block {
            grid-column-start: 1;
            grid-column-end: 2;
            background-image: none !important;
            height: 270px;
            &:before {
                display: none;
            }
            & > a {
                text-decoration: none;
                h3 {
                    position: static;
                    width: 100%;
                    margin-top: 40px;
                    text-align: center;
                    @include font-size(32); 
                }
                .home__content--block_content {
                    position: static;
                    transform: none;
                    padding: 0 35px;
                    opacity: 1;
                    text-align: center;
                    h4 {
                        margin-top: 15px;
                        max-width: none;
                        @include font-size(18); 
                    }
                    p {
                        margin-top: 15px;
                        max-width: none;
                    }                    
                }
                .button {
                    position: static;
                    transform: none;
                    opacity: 1;
                    margin: 25px auto 0;
                    display: block;
                    width: 150px;
                    text-align: center;
                }
            }
            &.work {
                grid-row-start: 2;
                grid-row-end: 3;
                background: $work;
            }
            &.marina {
                grid-row-start: 3;
                grid-row-end: 4;
                background: $marina;
            }
            &.life {
                grid-row-start: 4;
                grid-row-end: 5;
                background: $life;
            }
        }
    }
    @media screen and (max-width: 750px) {
        .home__content--block {
            height: 300px;    
        }
    }
    @media screen and (max-width: 540px) {
        .home__content--block {
            height: 345px;    
        }
    }
}

*::-ms-backdrop, .home__content {
    grid-template-columns: 80% 20%;
}