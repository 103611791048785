body.life {
    .hero {
        background-image: url('/images/life_hero.jpg');
    }
    .menu {
        background: $life;
        ul { 
            width: 100%;
            max-width: 1050px;
            margin: 0 auto;
            li.active:after {
                border-top-color: $life;
            }
        }
        &:after {
            background: linear-gradient(to right, transparent, $life);
        }
    }
    .blocks {
        .text {
            color: $dark_blue;
            &.life-pattern, &.transport-pattern {            
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
                background-image: url('/images/life_pattern.png');
                color: #FFF;
            }
            &.transport-pattern {
                background-image: url('/images/transport_pattern.png');
            }
        }        
        .quote {
            &:before, &:after {                
                background-image: url('/images/quote_blue.svg');
            }            
        }
        .button {
            margin: 20px 0;
            color: #FFF;
            @include font-size(10);
            &:hover {
                color: $dark_blue;
            }
            &.dark {
                border-color: $dark_blue;
                color: $dark_blue;
            }
        }
        .mid .button {
            margin: 0;
        }
        .light-blue, .alt-blue, .mid-blue {
            &, a {
                color: #FFF;        
            }            
        }        
        .blue, .light-blue {
            background: $light_blue;
            a {
                color: #FFF;
            }
        }
        .alt-blue {
            background: $alt_blue;
        }
        .mid-blue {
            background: $mid_blue;
        }
        @media screen and (max-width: 980px) {
            .center-on-one-column {
                background-position: center center;
            }
        }
        &.events {
            grid-template-columns: calc(33.33vw - 75px) calc(33.33vw - 75px) calc(33.33vw - 75px);
            grid-template-rows: calc(33.33vw - 75px);
            grid-auto-rows: calc(33.33vw - 75px);
            grid-auto-flow: row;
            grid-gap: 50px;
            gap: 50px;
            border: 50px solid #FFF;
            border-bottom: 0;
            @media screen and (min-width: 1500px) {
                grid-template-columns: 433px auto 433px;
                grid-template-rows: auto;
                grid-auto-rows: auto;
            }
            @media screen and (max-width: 1200px) {
                grid-template-columns: calc(50vw - 90px) calc(50vw - 90px);
                grid-template-rows: auto;
                grid-auto-rows: auto;  
            }
            @media screen and (max-width: 980px) {
                grid-template-columns: calc(100vw - 75px);
                grid-gap: 25px;
                gap: 25px;
                border: 25px solid #FFF;
            }
            .event {
                position: relative;
                padding: 20px 0 0 20px;
                overflow: hidden;            
                -ms-grid-column-span: 1;
                .event__image-surround {
                    overflow: hidden;
                }
                .event__image {
                    height: 190px;
                    background-size: cover;
                    background-position: center center;
                    background-repeat: no-repeat;
                    transition: transform .5s ease;
                }
                .event__info {
                    text-align: center;
                    padding: 20px 15px;
                    color: #FFF;
                    h2 {
                        font-weight: 500;
                        @include font-size(12);
                    }
                    p {
                        @include font-size(10);
                    }
                    .button {
                        margin-top: 10px;
                        @include font-size(9);
                    }
                }
                &:hover .event__image {
                    transform: scale(1.1);
                }
                &:before {
                    position: absolute;
                    content: "";
                    display: block;
                    left: -75px;
                    top: -75px;
                    z-index: 150;
                    width: 150px;
                    height: 150px;
                    transform: rotate(-45deg);
                    color: #FFF;
                    text-align: center;
                    padding-top: 120px;
                }
                &:nth-child(6n+1) {
                    &:before, .event__info {
                        background: $life_event_yellow;
                        color: $dark_blue;                        
                    }
                    .button {
                        border-color: $dark_blue;
                        color: $dark_blue;
                        &:hover {
                            border-color: #FFF;
                        }
                    }
                }
                &:nth-child(6n+2) {
                    &:before, .event__info {
                        background: $life_event_pink;
                    }
                }
                &:nth-child(6n+3) {
                    &:before, .event__info {
                        background: $life_event_plum;
                    }
                }
                &:nth-child(6n+4) {
                    &:before, .event__info {
                        background: $life_event_blue;
                    }
                }
                &:nth-child(6n+5) {
                    &:before, .event__info {
                        background: $life_event_teal;
                    }
                }
                &:nth-child(6n+6) {
                    &:before, .event__info {
                        background: $life_event_green;
                    }
                }                
                &:nth-child(3n+1) {
                    -ms-grid-column: 1;
                }
                &:nth-child(3n+2) {
                    -ms-grid-column: 2;
                }
                &:nth-child(3n) {
                    -ms-grid-column: 3;
                }
                &:nth-child(4), &:nth-child(5), &:nth-child(6) {
                    -ms-grid-row: 2;
                }
                &:nth-child(7), &:nth-child(8), &:nth-child(9) {
                    -ms-grid-row: 3;
                }
                &.event--event:before {
                    content: "EVENT";
                }
                &.event--festival:before {
                    content: "FESTIVAL";
                }
                &.event--race:before {
                    content: "RACE";
                }
            }
            
        }
    }
}

body.life.transport {
    .plum {
        background: $work_plum;
        color: #FFF;
    }
    .blue {
        background: $light_blue;
        color: #FFF;
    }
}