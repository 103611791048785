/* Fonts */
@import url("https://use.typekit.net/tgv7zsj.css");
@font-face {
    font-family: 'intro_black_capsregular';
    src: url('intro-black-caps-webfont.woff2') format('woff2'),
         url('intro-black-caps-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/* Typography */
body {
    font-family: effra, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    margin: 0;
}

h1 {
    @include font-size(44);
}

h2 {
    @include font-size(25);
}

h3 {
    @include font-size(22);
}

h4 {
    @include font-size(18);
}

h5 {
    @include font-size(14);
}

h6 {
    @include font-size(12);
}

p, li {    
    font-weight: 300;
    margin-bottom: 1em;
    @include font-size(12);    
}

p:last-of-type {
    margin-bottom: 0;
}