body.marina {
    .hero {
        background-image: url('/images/marina_hero.jpg');
    }
    .menu {
        background: $marina;
        ul li.active:after {
            border-top-color: $marina;
        }
        &:after {
            background: linear-gradient(to right, transparent, $marina);
        }
    }
    .blocks {
        .text {
            color: $dark_blue;
            &.marina-pattern, &.transport-pattern {                
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
                background-image: url('/images/marina_pattern.png');
                color: #FFF;
            }
        }        
        .quote {
            &:before, &:after {                
                background-image: url('/images/quote_blue.svg');
            }            
        }
        .picture.with-text h2 {
            @include font-size(34);
        }
        .button {
            margin: 20px 0;
            color: #FFF;
            @include font-size(10);
            &:hover {
                color: $dark_blue;
            }
        }
        .mid .button {
            margin: 0;
        }
        .light-blue, .alt-blue, .mid-blue {
            &, a {
                color: #FFF;        
            }            
        }        
        .blue, .light-blue {
            background: $light_blue;
            a {
                color: #FFF;
            }
        }
        .alt-blue {
            background: $alt_blue;
        }
        .mid-blue {
            background: $mid_blue;
        }
    }
    .marina__modal {
        display: none;
        padding: 50px;
        background: $dark_blue;
        text-align: center;
        color: #FFF;
        p, li {
            @include font-size(11);
        }
        p {
            margin-bottom: 1.5em;
        }
        ul {
            margin: 0 0 1em 20px;
            li {
                margin: 0;
            }
        }
        h3 {
            margin: 5px 0 0;
            text-transform: uppercase;
            font-weight: 500;
            @include font-size(12);
        }
    }
    .marina__berthing {
        h3 {
            span {
                display: block;
                @include font-size(10);
            }
        }
        p {
            margin: 0;
            @include font-size(10);
        }
        select {
            width: 100%;
            max-width: 500px;
            padding: 5px 10px;
            margin-top: 20px;
            color: $dark_blue;
            @include font-size(10);
        }
        div[data-band] {
            display: none;
            justify-content: space-around;
            flex-wrap: wrap;
            max-width: 500px;
            @include font-size(9);
            &:first-child {
                display: flex;
            }
            & > div {
                flex-basis: 33%;
                padding-top: 20px;
            }
            span, strong {
                display: block;
                @include font-size(7);
            }
            strong {
                font-weight: 500;
            }
        }
    }
}

body.marina.transport {
    .plum {
        background: $mid_blue;
        color: #FFF;
    }
    .blue {
        background: $light_blue;
        color: #FFF;
    }
}