body {
    max-width: 1500px;
    padding-top: 130px;
    margin: 0 auto !important;
    overflow-x: hidden;
    background: #EEE;
    &.customize-support header {
        top: 32px;        
        @media screen and (max-width: 768px) {
            top: 46px; 
        }
    }
    @media screen and (max-width: 980px) {
        padding-top: 90px;
    }
}

img {
    max-width: 100%;
}

.wrapper {
    max-width: 1500px;
    padding: 0 35px;
    margin: 0 auto;
    &.narrow {
        max-width: 1000px;
    }
    @media screen and (max-width: 600px) {
        padding: 0 25px;    
    }
}

header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 130px;
    padding: 40px 0;
    background: #FFF;
    transition: .25s ease;
    img {
        transition: .25s ease;
    }
    nav {
        display: flex;
        justify-content: space-between;
        & > ul {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 50px;
            &:first-child {
                width: 230px;
            }
            &:last-child {
                width: 380px;
            }
            li {
                margin: 0;
                a { 
                    transition: all .2s ease;
                    text-transform: uppercase;                    
                    font-weight: 500;
                    @include font-size(10);
                    color: $dark_blue;
                    &:hover {
                        text-decoration: none;
                    }
                    &.work {
                        &:hover, &.active {
                            color: $work;
                        }
                    }
                    &.marina {
                        &:hover, &.active {
                            color: $marina;
                        }
                    }
                    &.life {
                        &:hover, &.active {
                            color: $life;
                        }
                    }
                }
                &.social a {
                    display: inline-block;
                    width: 25px;
                    height: 25px;
                    margin: 0 8px;
                    padding-top: 2px;
                    background: $dark_blue;
                    border-radius: 50%;
                    text-align: center;
                    color: #FFF;
                    @include font-size(9);
                    &:hover {
                        background: $marina;
                    }
                }
            }
            ul {
                display: none;
            }
        }        
    }
    svg {
        position: absolute;
        width: 110px;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
    .header__burger {
        display: none;
        position: absolute;
        width: 42px;
        height: 40px;
        top: 42px;
        right: 35px;
        transition: .5s ease-in-out;
        cursor: pointer;
        span {
            display: block;
            position: absolute;
            height: 3px;
            width: 34px;
            opacity: 1;
            left: 0;
            transform: rotate(0deg);
            transition: .25s ease-in-out;        
            &:nth-child(1) {
                top: 0;
            }
            &:nth-child(2), &:nth-child(3) {
                top: 13px;
            }
            &:nth-child(4) {
                top: 26px;
            }        
        }
        &.open {
            span {
                &:nth-child(1) {
                  top: 18px;
                  width: 0%;
                  left: 50%;
                }
                &:nth-child(2) {
                    transform: rotate(45deg);
                    width: 100%;
                }
                &:nth-child(3) {
                    transform: rotate(-45deg);
                    width: 100%;
                }
                &:nth-child(4) {
                    top: 18px;
                    width: 0%;
                    left: 50%;
                }
            }
        }
    }
    .header__burger {
        display: none;
        width: 42px;
        height: 40px;
        position: absolute;
        top: 25px;
        right: 25px;
        transform: rotate(0deg);
        transition: .5s ease-in-out;
        cursor: pointer;
        span {
            display: block;
            position: absolute;
            height: 3px;
            width: 100%;
            background: $dark_blue;
            opacity: 1;
            left: 0;
            transform: rotate(0deg);
            transition: .25s ease-in-out;        
            &:nth-child(1) {
                top: 0;
            }
            &:nth-child(2), &:nth-child(3) {
                top: 17px;
            }
            &:nth-child(4) {
                top: 34px;
            }        
        }
        &.open {
            span {
                &:nth-child(1) {
                  top: 18px;
                  width: 0%;
                  left: 50%;
                }
                &:nth-child(2) {
                    transform: rotate(45deg);
                    width: 100%;
                }
                &:nth-child(3) {
                    transform: rotate(-45deg);
                    width: 100%;
                }
                &:nth-child(4) {
                    top: 18px;
                    width: 0%;
                    left: 50%;
                }
            }
        }
    }
    @media screen and (max-width: 980px) {
        padding: 0;
        height: 90px;
        .wrapper {
            padding: 0;
        }
        svg {
            width: 75px;
            left: 25px;
            transform: translateY(-50%);
        }
        .header__burger {
            display: block;
            position: absolute;
            top: 50%;
            right: 25px;
            transform: translateY(-50%);
        }
        nav {
            display: none;
            position: relative;
            top: 130px;
            background: #FFF;
            ul {
                display: block;
                width: 100% !important;
                height: auto;
                li {
                    text-align: center;
                    a {
                        display: block;
                        padding: 15px 0;
                        @include font-size(18);
                        &:hover {
                            color: $dark_blue !important;
                        }
                        &.work, &.marina, &.life {
                            color: #FFF !important;
                        }
                        &.work {
                            background: $work;
                        }
                        &.marina {
                            background: $marina;
                        }
                        &.life {
                            background: $life;
                        }
                    }
                    &.social {
                        padding: 20px;
                        text-align: center;
                        a:hover {
                            background: $light_blue !important;
                            color: #FFF !important;
                        }
                    }
                }
                ul {
                    li a {
                        padding: 10px 0;
                        @include font-size(14);
                    }
                }
                &:first-child, &:nth-child(2) li:not(.social) {
                    border-bottom: 1px solid $dark_blue;
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        nav {
            top: 90px;
        }
    }
}

.hero {
    position: relative;
    height: 45vh;
    min-height: 450px;
    max-height: 575px;
    background-size: cover;
    &:after {   
        content: "";
        position: absolute;
        left: 0;
        bottom: 0; 
        width: 100%;
        height: 200px;
        background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); 
        background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); 
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
    }
    h1, h2 {
        position: absolute;
        left: 0;
        z-index: 100;
        width: 100%;
        text-align: center;
        color: #FFF;
    }
    h1 {            
        top: 40px; 
        left: 50%;
        transform: translateX(-50%);
        max-width: 750px;
        margin: 0 auto; 
        font-family: 'intro_black_capsregular' !important;
        line-height: 90%;            
        text-transform: uppercase;            
        @include font-size(60);
        & > span {
            display: block;
            margin-bottom: -20px;
            @include font-size(24);
        }
        @media screen and (max-width: 980px) {
            @include font-size(64);
            & > span {
                @include font-size(26);
            }
        }
        @media screen and (max-width: 600px) {
            padding: 0 25px;            
            @include font-size(30);
            & > span {
                margin-bottom: 15px;
                line-height: 100%;
                @include font-size(16);
            }
        }
    }
    h2 {
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        max-width: 620px;
        margin: 0 auto;
        font-weight: 500;
        text-shadow: 0 0 20px #222;
        @include font-size(16);
        @media screen and (max-width: 600px) {
            padding: 0 25px;
            @include font-size(14);
        }
    }
}

.menu {
    background: $dark_blue;
    ul {
        display: flex;
        justify-content: space-between;
        li {
            position: relative;
            padding: 14px 0;
            margin: 0;
            background-color: inherit;
            a {
                text-transform: uppercase;
                font-weight: 500;
                color: #FFF;
                &:hover {
                    text-decoration: none;
                }
            }
            &.active:after {
                position: absolute;
                content: "";
                display: block;
                width: 50px;
                border-top: 20px solid $dark_blue;
                border-left: 25px solid transparent;
                border-right: 25px solid transparent;
                background: transparent;
                bottom: -20px;
                left: 50%;
                z-index: 100;
                transform: translateX(-50%);
            }
        }        
    }
    @media screen and (max-width: 600px) {
        display: none;
        position: relative;
        .wrapper {
            padding: 0;
        }
        ul {
            display: block;
            height: 56px;
            overflow: auto;
            overflow-y: hidden;
            white-space: nowrap;
            li {
                display: inline-block;
                margin-left: 20px;
                @include font-size(11);
                &:last-child {
                    margin-right: 20px;
                }
            }
        }
        &:after {
            position: absolute;
            display: block;
            content: "";
            top: 0;
            right: 0;
            height: 56px;
            width: 50px;
            background: linear-gradient(to right, transparent, $work);
        }
    }
    &.sticky {
        position: fixed;
        top: 130px;
        left: 50%;
        z-index: 300;
        width: 100%;
        max-width: 1500px;
        transform: translateX(-50%);
        @media screen and (max-width: 980px) {
            top: 90px;    
        }
    }
}

body.customize-support .menu.sticky {
    top: 162px;
    @media screen and (max-width: 980px) {
        top: 136px;    
    }
}

section.main-image {
    position: relative;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 500px;
    h2 {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 900px;
        transform: translate(-50%, -50%);
        line-height: 100%;
        text-align: center;
        text-shadow: 0 0 20px #222;
        font-weight: 300;
        color: #FFF;
        @include font-size(42);
    }
}

section.blocks {
    display: grid;
    grid-template-columns: 33.33vw 33.33vw 33.33vw;
    grid-template-rows: 33.33vw;
    background: #FFF;
    overflow: hidden;
    .show-on-two-column {
        display: none;
    }
    @media screen and (min-width: 1500px) {
        grid-template-columns: 500px 500px 500px;
    }
    @media screen and (min-width: 981px) and (max-width: 1180px) {
        grid-template-columns: 50vw 50vw;
        & > div {            
            min-height: 350px;
        }
        .hide-on-two-column {
            display: none;
        }
        .show-on-two-column {
            display: block;
            &.second-row {
                grid-row-start: 2 !important;
                grid-row-end: 3 !important;
            }
            @media screen and (max-width: 980px) {
                grid-row-start: 1 !important;
                grid-row-end: 2 !important;
            }
        }
        .full-on-two-column {
            grid-column-start: 1 !important;
        }
        .first-on-two-column {
            grid-column-start: 1 !important;
        }
        .second-on-two-column {
            grid-column-start: 2 !important;
        }        
    }
    @media screen and (max-width: 980px) {
        grid-template-columns: 100vw;
        & > div {
            grid-column-start: 1 !important;
            grid-column-end: 2 !important;            
            min-height: 350px;
            &:first-child {
                grid-row-start: 1 !important;
                grid-row-end: 2 !important;
            }
            &:nth-child(2) {
                grid-row-start: 2 !important;
                grid-row-end: 3 !important;
            }
            &:nth-child(3) {
                grid-row-start: 3 !important;
                grid-row-end: 4 !important;
            }
        } 
        .hide-on-single-column {
            display: none;
        }
        &.switch-on-single-column {
            & > div {
                &:first-child {
                    grid-row-start: 2 !important;
                    grid-row-end: 3 !important;
                }
                &:nth-child(2) {
                    grid-row-start: 1 !important;
                    grid-row-end: 2 !important;
                }
            }
            &.three-thirds {
                &:nth-child(3) {
                    grid-row-start: 3 !important;
                    grid-row-end: 4 !important;
                }
            }
        }
    }
    @media screen and (max-width: 600px) {
        & > div {
            min-height: inherit;
        }
    }
    &.thirds {      
        @media screen and (min-width: 1500px) {
            grid-template-rows: 500px;
        }
        @media screen and (max-width: 1180px) {
            grid-template-rows: auto;            
        }
        @media screen and (max-width: 980px) {
            grid-template-rows: auto auto auto;
        }
        &.large-height {
            grid-template-rows: 630px;
        }
    }
    &.double-height-right, &.large-two-by-two {
        grid-template-rows: 33.33vw 33.33vw;
        @media screen and (min-width: 1500px) {
            grid-template-rows: 500px 500px;
        }
    }
    &.double-height-right {
        & > div {
            &:first-child {
                grid-column-start: 1;
                grid-column-end: 2;
                grid-row-start: 1;
                grid-row-end: 1;
            }
            &:nth-child(2) {
                grid-column-start: 2;
                grid-column-end: 3;
                grid-row-start: 1;
                grid-row-end: 1;
            }
            &:nth-child(3) {
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-start: 2;
                grid-row-end: 3;
                -ms-grid-column-span: 2;
            }
            &:last-child {
                grid-column-start: 3;
                grid-column-end: 4;
                grid-row-start: 1;
                grid-row-end: 3;
                -ms-grid-row-span: 2;
            }
        }
        @media screen and (max-width: 980px) {
            grid-template-rows: auto auto auto auto;
            & > div {
                min-height: 350px; 
                &:first-child {
                    grid-row-start: 1;
                    grid-row-end: 2;
                }
                &:nth-child(2) {
                    grid-row-start: 2;
                    grid-row-end: 3;
                }
                &:nth-child(3) {
                    grid-row-start: 3;
                    grid-row-end: 4;
                }
                &:last-child {
                    grid-row-start: 5;
                    grid-row-end: 6;
                }
            }
        }
    }
    &.large-two-by-two { 
        & > div {
            &:first-child {
                grid-column-start: 1;
                grid-column-end: 2;
                grid-row-start: 1;
                grid-row-end: 1;
            }
            &:nth-child(2) {
                grid-column-start: 1;
                grid-column-end: 2;
                grid-row-start: 2;
                grid-row-end: 3;
            }
            &:last-child {
                grid-column-start: 2;
                grid-column-end: 4;
                grid-row-start: 1;
                grid-row-end: 3;
                -ms-grid-column-span: 2;
                -ms-grid-row-span: 2;
            }            
        }
        &.left {
            & > div {
                &:first-child {
                    grid-column-start: 1;
                    grid-column-end: 3;
                    grid-row-start: 1;
                    grid-row-end: 3;
                    -ms-grid-column-span: 2;
                    -ms-grid-row-span: 2;
                }
                &:nth-child(2) {
                    grid-column-start: 3;
                    grid-column-end: 4;
                    grid-row-start: 1;
                    grid-row-end: 2;
                }
                &:last-child {
                    grid-column-start: 3;
                    grid-column-end: 4;
                    grid-row-start: 2;
                    grid-row-end: 3;
                }
                @media screen and (max-width: 1180px) {
                    &:first-child {
                        grid-column-start: 1;
                        grid-column-end: 2;
                    }
                    &:nth-child(2), &:last-child {
                        grid-column-start: 2;
                        grid-column-end: 3;
                    }
                }
            }
        }
        @media screen and (max-width: 980px) {
            grid-template-rows: auto auto auto;
        }
    }
    &.one-two-thirds {
        & > div {
            grid-row-start: 1;
            grid-row-end: 1;
            &:first-child {
                grid-column-start: 1;
                grid-column-end: 2;
            }
            &:last-child {
                grid-column-start: 2;
                grid-column-end: 4;
                -ms-grid-column-span: 2;
            }
        }
        @media screen and (max-width: 980px) {
            grid-template-rows: auto auto !important;            
        }
    }
    &.two-one-thirds {
        & > div {
            grid-row-start: 1;
            grid-row-end: 2;
            &:first-child {
                grid-column-start: 1;
                grid-column-end: 3;
                -ms-grid-column-span: 2;
            }
            &:last-child {
                grid-column-start: 3;
                grid-column-end: 4;
                
            }
        }
        @media screen and (max-width: 980px) {
            grid-template-rows: auto auto;
        }
    }
    @media screen and (max-width: 1180px) {
        &.one-two-thirds, &.two-one-thirds {
            & > div {
                &:first-child {
                    grid-column-start: 1;
                    grid-column-end: 2;
                }
                &:last-child {
                    grid-column-start: 2;
                    grid-column-end: 3;
                }
            }
        }
    }
    &.three-thirds {
        & > div {
            grid-row-start: 1;
            grid-row-end: 2;
            &:first-child {
                grid-column-start: 1;
                grid-column-end: 2;
            }
            &:nth-child(2) {
                grid-column-start: 2;
                grid-column-end: 3;
            }
            &:last-child {
                grid-column-start: 3;
                grid-column-end: 4;
            }            
        }
        @media screen and (max-width: 1180px) {
            grid-template-rows: auto auto;
            & > div:last-child {
                grid-row-start: 2;
                grid-row-end: 3;
                grid-column-start: 1;
                grid-column-end: 3;
            }
        }
    }
    &.two-sixths {
        grid-template-columns: 33.33vw 33.33vw 16.666vw 16.665vw;
        @media screen and (min-width: 1500px) {
            grid-template-columns: 500px 500px 250px 250px;
        }
        @media screen and (max-width: 1180px) {
            grid-template-columns: 50vw 50vw;
        }
        & > div {
            grid-row-start: 1;
            grid-row-end: 1;
            &:first-child {
                grid-column-start: 1;
                grid-column-end: 2;
            }
            &:nth-child(2) {
                grid-column-start: 2;
                grid-column-end: 3;
            }
            &:nth-child(3) {
                grid-column-start: 3;
                grid-column-end: 4;
            }
            &:last-child {
                grid-column-start: 4;
                grid-column-end: 5;
            }
            @media screen and (max-width: 980px) {
                grid-column-start: 1 !important;
                grid-column-end: 3 !important;                
                &:nth-child(3) {
                    grid-column-start: 1 !important;
                    grid-column-end: 2 !important;
                }
                &:last-child {
                    grid-row-start: 3;
                    grid-row-end: 4;
                    grid-column-start: 2 !important;
                    grid-column-end: 3 !important;
                }
            }
        }
    }
    &.small-column-middle {
        grid-template-columns: 33.33vw 16.67vw 50vw;
        & > div {
            grid-row-start: 1;
            grid-row-end: 1;
            &:first-child {
                grid-column-start: 1;
                grid-column-end: 2;
            }
            &:nth-child(2) {
                grid-column-start: 2;
                grid-column-end: 3;
            }
            &:nth-child(3) {
                grid-column-start: 3;
                grid-column-end: 4;
            }
        }
        @media screen and (min-width: 1500px) {
            grid-template-rows: 500px;
            grid-template-columns: 500px 250px 750px;
        }
        @media screen and (max-width: 1180px) {
            grid-template-columns: 50vw 50vw;
        }
        @media screen and (max-width: 980px) {
            grid-template-columns: 100vw;
            grid-template-rows: auto;            
        }
    }
    &.small-column-right {
        grid-template-columns: 50vw 33.33vw 16.67vw;
        & > div {
            grid-row-start: 1;
            grid-row-end: 1;
            &:first-child {
                grid-column-start: 1;
                grid-column-end: 2;
            }
            &:nth-child(2) {
                grid-column-start: 2;
                grid-column-end: 3;
            }
            &:nth-child(3) {
                grid-column-start: 3;
                grid-column-end: 4;
            }
        }
        @media screen and (min-width: 1500px) {
            grid-template-rows: 500px;
            grid-template-columns: 750px 500px 250px;
        }
        @media screen and (max-width: 1180px) {
            grid-template-columns: 50vw 50vw;
        }
        @media screen and (max-width: 980px) {
            grid-template-columns: 100vw;
            grid-template-rows: auto;       
        }
    }
    .row {
        width: 100%;
        @media screen and (max-width: 980px) {
            width: auto;     
        }
    }
    .text {
        display: flex;
        align-items: center;
        padding: 50px;
        overflow: hidden;
        ul {
            margin: 0;
            li {
                margin: 0 0 12px 20px;
                line-height: 110%;
                list-style: disc;
                &:last-child {
                    margin-bottom: 0;
                }
                @media screen and (max-width: 1180px) {
                    margin-bottom: 5px;
                }
            }
        }        
        h3, h4 {
            text-transform: uppercase;
            font-weight: 500;
            margin-bottom: 15px;
        }
        h3 {
            @include font-size(16);
        }
        h4 {
            margin: 5px 0 10px;
            @include font-size(13);
        }
        p {
            line-height: 140%;
            strong {
                font-weight: 500;
            }
        }
        .text--content {
            flex-basis: 100%;
        }
        .text--cols {
            column-count: 2;
            column-gap: 2em;
            @media screen and (max-width: 980px) {
                column-count: 1;    
            }
        }
        .text__list-block {
            border: 1px solid #FFF;
            padding: 20px;
            margin-bottom: 20px;  
            strong {
                font-weight: 500;
            }
            &:last-child {
                margin-bottom: 0;
            }
            @media screen and (max-width: 600px) {
                margin-top: 25px;    
            }
        }
        .small {
            @include font-size(11);
        }
        &.quote {
            position: relative;            
            text-align: center;
            &:before, &:after {
                content: "";
                position: absolute;
                display: block;
                width: 50px;
                height: 30px;
                left: 50%;
                top: 50px;
                background-image: url('/images/quote.svg');
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
                transform: translateX(-50%);
            }
            &:after {
                top: auto;
                bottom: 50px;
                transform: translateX(-50%) scale(-1, -1);
            }
            h3 {
                @include font-size(14);
            }
            p {
                font-style: italic;
                font-weight: 400;
                @include font-size(10);
                &.quote__author {
                    font-style: normal;
                    font-weight: 500;
                }
            }
            @media screen and (max-width: 600px) {
                &:before {
                    top: 25px;
                }    
                &:after {
                    bottom: 25px;
                }
            }
        }
        &.large, &.mid {
            p {
                line-height: 110%;
                text-align: center;
                font-weight: 300;
                @include font-size(24);
            }
        }
        &.mid p {
            @include font-size(18);
        }
        @media screen and (max-width: 600px) {
            padding: 25px;    
        }
    }
    .picture {        
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        &.with-text {
            position: relative;
            background-position: center top;
            h2 {
                position: absolute;
                top: 40px;
                left: 0;
                width: 100%;
                padding: 0 70px;
                text-align: center;
                color: #FFF;
                font-weight: 300;
                line-height: 110%;
                @include font-size(28);
                @media screen and (max-width: 980px) {
                    padding: 0 25px;
                    @include font-size(26);
                }
            }
            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 250px;
                background: -moz-linear-gradient(top, rgba(65, 192, 240, .6) 0%, rgba(0,0,0,0) 100%); 
                background: -webkit-linear-gradient(top, rgba(65, 192, 240, .6) 0%,rgba(0,0,0,0) 100%); 
                background: linear-gradient(to bottom, rgba(65, 192, 240, .6) 0%,rgba(0,0,0,0) 100%);
            }
        }
        &.with-logo {
            position: relative;
            img {
                position: absolute;
                bottom: 50px;
                left: 50px;
            }
        }
    }
    @supports not (display: grid) {
        display: flex;
    }
}

body.transport .one-two-thirds .text-right {
    padding-right: 0;
}

footer {
    padding: 20px 0;
    background: #FFF;
    ul {
        display: flex;
        justify-content: space-between;
        li {
            margin: 0;
            @include font-size(9);
            a {
                color: #000;
                &:hover {
                    color: $dark_blue;
                    text-decoration: none;
                }
            }
        }
        @media screen and (max-width: 600px) {
            display: block;
            text-align: center;
            li {
                margin: 15px 0;
                @include font-size(11);
            }
        }
    }
}

.contact-us__modal {
    display: none;
    min-width: 700px;
    padding: 50px;
    background: $dark_blue;
    text-align: center;
    color: #FFF;
    p, li {
        @include font-size(11);
    }
    p {
        max-width: 550px;
        margin: 0 auto 1.5em;
    }
    h2 {
        margin: 0 0 20px;
        text-transform: uppercase;
        font-weight: 500;
        @include font-size(20);
    }
    h3 {
        margin: 5px 0 0;
        text-transform: uppercase;
        font-weight: 500;
        @include font-size(12);
    }
    a {
        color: #FFF;
    }
    form {
        margin-top: 15px;
        p {
            max-width: none;
        }
        input, textarea {
            width: 100%;
            margin: 0 0 10px;
            padding: 10px 15px;
            border: none;
            color: $dark_blue;
            @include font-size(10);
        }
        textarea {
            height: 120px;
            resize: none;
        }
        input[type=submit] {
            background: transparent;
            border: 1px solid #FFF;
            color: #FFF;
            width: auto;
            margin: 0 auto;
            padding: 10px 25px;
            text-transform: uppercase;
            &:hover {
                background: #FFF;
                color: $dark_blue;
            }
        }
    }
    @media screen and (max-width: 980px) {
        min-width: inherit; 
        max-width: 100%;
        padding: 25px;
    }
}

.text_only__content {
    .wrapper {
        max-width: 1000px;
    }
    padding: 50px;
    background: #FFF;
    h1 {
        text-align: center;
        padding: 20px 0;
        font-weight: 500;
        color: $dark_blue;
        @include font-size(24);
        @media screen and (max-width: 600px) {
            text-align: left;
            @include font-size(28);
        }
    }
    h2 {
        margin: 35px 0 10px;
        color: $dark_blue;
        font-weight: 500;
        @include font-size(15);
        @media screen and (max-width: 600px) {
            @include font-size(14);
        }
    }
    p, li, th, td {
        color: $dark_blue;
        @include font-size(10);
    }
    ul {
        margin: 0 0 20px 40px;
        li {
            margin-bottom: 8px;
            list-style: disc;
        }
    }
    strong {
        font-weight: 500;
    }
    table {
        border-collapse: separate;
        border-spacing: 1px;
        background: #DDD;
        margin-bottom: 2em;
        td, th {
            padding: 10px 15px;
            background: #FFF;
            font-weight: 300;
            vertical-align: top;
        }
        th {
            font-weight: 500;
            background: #EEE;
        }
    }
}

.button {
    display: inline-block;
    padding: 5px 20px;
    border: 1px solid #FFF;
    cursor: pointer;
    font-weight: 500;
    text-decoration: none !important;
    transition: all .3s ease;
    &:hover {
        background: #FFF;
        color: #000;
    }
    &:last-child {
        margin-bottom: 0 !important;
    }
}

.anchor-link {
    display: block;
    margin-top: -120px;
    padding-top: 120px;
    pointer-events: none;
}

.fancybox-content {
    padding: 0;
}

.fancybox-toolbar {
    opacity: 1;
    visibility: visible;
}

.fancybox-button--close {
    width: 70px;
    height: 70px;
    color: #FFF;
}

body.customize-support {
    .fancybox-infobar, .fancybox-toolbar {
        top: 32px;
    }
}

.fancybox-button--zoom {
    display: none;
}

@media screen and (max-width: 790px) {
    #wpadminbar {
        z-index: 500 !important;
        position: fixed !important;
    }    
}