/* Colours */
$work:   #D7007E;
$marina: #1D71B8;
$life:   #BCCF00;

$work_teal: #6BBE9E;
$work_lime: #BCCF00;
$work_plum: #281D67;

$light_blue: #41C0F0;
$alt_blue:   #36A9E1;
$mid_blue:   $marina;
$dark_blue:  #281D67;

$life_event_yellow: #E8E100;
$life_event_pink:   $work;
$life_event_plum:   $work_plum;
$life_event_blue:   $marina;
$life_event_teal:   $work_teal;
$life_event_green:  $work_lime;